#root {
  font-family: Roboto  Helvetica, Arial, sans-serif;
  color: #070712;

  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  overflow:scroll;
}
.c-page {
  width: 100%;
  min-height: 100%;
}
.c-card {
  background-color: #FFFFFF;
  border-radius: 2.133333333vw;
}
.c-card-2 {
  background-color: #FFFFFF;
  border-radius: 2.666666666vw;
}
.c-shadow {
  box-shadow:1.333333333vw 1.333333333vw 1.333333333vw rgba(19, 19, 19, 0.2);
}
.c-h10 {
  font-size: 2.666666666vw;
  line-height: 3.2vw;
}
.c-h12 {
  font-size: 3.2vw;
  line-height: 4.266666666vw;
}
.c-h14 {
  font-size: 3.733333333vw;
  line-height: 4.266666666vw;
}
.c-h16 {
  font-size: 4.266666666vw;
  line-height: 5.866666666vw;
}
.c-h30 {
  font-size: 8vw;
  line-height: 8.533333333vw;
}
.c-w-600 {
  font-weight: 600;
}
.c-m-t-7 {
  margin-top: 1.866666666vw;
}
.c-m-t-8 {
  margin-top: 2.133333333vw;
}
.c-m-t-16 {
  margin-top: 4.266666666vw;
}
.c-m-t-26 {
  margin-top: 6.933333333vw;
}
.c-m-t-30 {
  margin-top: 8vw;
}
.c-m-t-32 {
  margin-top: 8.533333333vw;
}
.c-m-r-16 {
  margin-right: 4.266666666vw;
}
.c-m-l-16 {
  margin-left: 4.266666666vw;
}
.c-p-16 {
  padding: 4.266666666vw;
}
.c-p-b-16 {
  padding-bottom: 4.266666666vw!important;
}
.c-p-s-16 {
  padding:0 4.266666666vw;
}
.c-m-r-16 {
  margin-right: 4.266666666vw;
}
.c-page-mobile {
  background-color: #EBEBEB;
  width: 100vw;
}
.c-bg-f {
  background-color: #FFFFFF!important;
}
.c-bg-e5 {
  background-color: #E5E5E5!important;
}
.c-bg-f6 {
  background-color: #F6F6F6!important;
}
.c-c-5 {
  color: #555555!important;
}
.c-c-9 {
  color: #999999!important;
}
.c-c-FF6951 {
  color: #FF6951;
}
.c-wp-100 {
  width: 100%;
  box-sizing: border-box;
}
.c-relative {
  position: relative;
}
.c-area-f-g-1 {
  flex-grow: 1;
}
.c-area-f-wrap {
  flex-wrap: wrap;
}
.c-area-f-r-s-b {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  box-sizing: border-box;
}
.c-area-f-r-s-s {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
}
.c-area-f-r-s-e {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  box-sizing: border-box;
}
.c-area-f-r-s-c {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
}
.c-area-f-r-e-s {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  box-sizing: border-box;
}
.c-area-f-r-e-c {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  box-sizing: border-box;
}
.c-area-f-r-c-c {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.c-area-f-r-c-b {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}
.c-area-f-r-c-s {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
}
.c-area-f-r-c-e {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
}
.c-area-f-c-c, .c-area-f-c-c-c {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.c-area-f-c-c-s {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
}
.c-area-f-c-s-s {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
}
.c-area-f-c-e-s {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  box-sizing: border-box;
}
.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #FFFFFF;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* ant design 样式 start */
:root:root {
  --adm-color-primary: #FD8702;
}
/* ant design 样式 end */